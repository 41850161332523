import { extendTheme, theme as base } from '@chakra-ui/react'
//breakpoints
// sm 480
// md 768
// lg 992
// xl 1440
export default extendTheme({
    initialColorMode: 'light',
    useSystemColorMode: false,
    sizes: {
        container: {
            xl: '1440px',
        },
    },
    colors: {
        white: '#FFFFFF',
        black: '#061935',
        blue: {
            50: '#e7eefa',
            100: '#cedef5',
            200: '#b6cdf0',
            300: '#6c9be2',
            400: '#0d6dff', //primary lighten
            500: '#0a58ce', //primary
            600: '#2243c5', //primary darken
            700: '#06357c',
            800: '#042352',
            900: '#021229',
        },
        grey: {
            50: '#f6f9fd',
            100: '#f4f7fd',
            200: '#f2f6fc',
            300: '#f0f5fc',
            400: '#b1bdd1', //grey lighten
            500: '#8f9bb3', //grey
            600: '#687799', //grey darken
            700: '#bdc2c9',
            800: '#a5a9b0',
            900: '#8f9bb3',
        },
        teal: {
            50: '#acfbff',
            100: '#7df6ff',
            200: '#4df3fe',
            300: '#28f0fd',
            400: '#12e1ef', //secondary lighten
            500: '#01b4c0', //secondary
            600: '#00939d', //secondary darken
            700: '#00484d',
            800: '#001a1c',
            900: '#001a1c',
        },
        pink: {
            50: '#ffe5e5', //400
            100: '#ffe5e5', //400
            200: '#d51238', //200
            300: '#f15964', //300
            400: '#ffe5e5', //400
            500: '#fab6b2', //500//highlist
            600: '#f58889', //600
            700: '#ee2a43', //700
            800: '#ee2a43', //700
            900: '#ee2a43', //700
        },
        red: {
            50: '#ffe2ec',
            100: '#ffe2ec',
            200: '#ffe2ec',
            300: '#ffb1c7',
            400: '#ff708d', //danger lighten
            500: '#ff3d71', //danger
            600: '#db2c66', //danger darken
            700: '#e5063e',
            800: '#b30030',
            900: '#810022',
        },
        light: {
            50: '#ffffff',
            100: '#ffffff',
            200: '#ffffff',
            300: '#f7f9fc',
            400: '#f7f9fc', //
            500: '#edf1f7', //light
            600: '#e4e9f2', //
            700: '#c5cee0',
            800: '#c5cee0',
            900: '#c5cee0',
        },
    },
    fonts: {
        heading: `Poppins, ${base.fonts.heading}`,
        body: `Poppins, ${base.fonts.body}`,
    },
    fontSizes: {
        xs: '12px',
        sm: '14px',
        md: '16px',
        lg: '18px',
        xl: '30px',
        '2xl': '36px',
        '3xl': '1.875rem',
        '4xl': '2.25rem',
        '5xl': '3rem',
        '6xl': '3.75rem',
        '7xl': '4.5rem',
        '8xl': '6rem',
        '9xl': '8rem',
    },
    radii: {
        none: '0',
        sm: '4px',
        base: '8px',
        md: '8px',
        lg: '16px',
        xl: '50%',
    },
    shadows: {
        base: '0 8px 16px 0 rgba(56, 96, 165, 0.15)',
    },
    components: {
        Button: {
            // 1. We can update the base styles
            baseStyle: {
                fontWeight: 'bold', // Normally, it is "semibold"
            },
            // 2. We can add a new button size or extend existing
            sizes: {
                sm: {
                    height: '24px',
                    paddingLeft: '16px',
                    paddingRight: '16px',
                    fontWeight: 'bold',
                    fontSize: '12px',
                },
                md: {
                    height: '32px',
                    fontSize: '12px',
                    textTransform: 'capitalize',
                    fontWeight: 'bold',
                    padding: '8px 16px',
                },
                lg: {
                    height: '48px',
                    fontSize: '16px',
                    textTransform: 'capitalize',
                    fontWeight: 'bold',
                    padding: '16px 24px',
                },
            },
            // 3. We can add a new visual variant
            variants: {
                'icon-button': {
                    height: '40px',
                    width: '40px',
                    padding: '4px',
                    backgroundColor: 'transparent',
                    color: 'grey.900',
                    ':hover': {
                        color: 'white',
                        backgroundColor: 'blue.500',
                    },
                    ':active': {
                        backgroundColor: 'blue.600',
                    },
                },
                'nav-button': {
                    color: 'colors.black',
                    ':hover': {
                        backgroundColor: 'light.400',
                    },
                    ':focus': {
                        backgroundColor: 'light.500',
                    },
                    ':active': {
                        backgroundColor: 'rgba(10, 88, 206, 0.08)',
                    },
                    backgroundColor: 'none',
                    textTransform: 'capitalize',
                },
                'left-nav-button': {
                    color: 'colors.black',
                    ':hover': {
                        backgroundColor: 'light.400',
                    },
                    ':focus': {
                        backgroundColor: 'light.500',
                    },
                    ':active': {
                        backgroundColor: 'rgba(10, 88, 206, 0.08)',
                    },
                    backgroundColor: 'colors.white',
                    textTransform: 'capitalize',
                    fontSize: '16px',
                    fontWeight: 'bold',
                    width: '100%',
                    maxWidth: '256px',
                    height: '56px',
                    marginTop: '0px !important',
                    marginBottom: '0px !important',
                    paddingLeft: '24px',
                    paddingRight: '24px',
                },
                'chip-blue': {
                    height: '24px',
                    paddingLeft: '16px',
                    paddingRight: '16px',
                    fontWeight: 'bold',
                    fontSize: '12px',
                    backgroundColor: 'blue.500',
                    color: 'white',
                    textTransform: 'none',
                    ':hover': {
                        backgroundColor: 'blue.600',
                    },
                    ':active': {
                        backgroundColor: 'blue.700',
                    },
                    marginLeft: '4px',
                    marginRight: '4px',
                    minWidth: '64px',
                },
                'chip-grey': {
                    height: '24px',
                    paddingLeft: '16px',
                    paddingRight: '16px',
                    fontWeight: 'bold',
                    fontSize: '12px',
                    color: 'black',
                    textTransform: 'none',
                    bg: '#edf1f7',
                    ':hover': {
                        bg: '#e4e9f2',
                    },
                    ':focus': {
                        backgroundColor: '#e4e9f2',
                    },
                    ':active': {
                        bg: '#c5cee0',
                    },
                    marginLeft: '4px',
                    marginRight: '4px',
                },
                'download-pdf': {
                    height: '40px',
                    width: '192px',
                    fontSize: '16px',
                    color: 'colors.black',
                    bg: '#edf1f7',
                    ':hover': {
                        bg: '#e4e9f2',
                    },
                    ':focus': {
                        backgroundColor: '#e4e9f2',
                    },
                    ':active': {
                        bg: '#c5cee0',
                    },
                    marginLeft: '24px',
                    marginRight: '24px',
                },
                'profile-save': {
                    fontSize: '14px',
                    alignSelf: 'end',
                    marginTop: '16px',
                    backgroundColor: 'blue.500',
                    color: 'white',
                },
                'select-menu': {
                    width: '100%',
                    paddingLeft: '0px',
                    borderRadius: '0px',
                    //borderBottom: `1px solid ${base.colors.gray[200]}`,
                    textAlign: 'start',
                    fontWeight: 'default',
                    fontSize: '16px',
                },
                grey: {
                    color: 'colors.black',
                    bg: '#edf1f7',
                    ':hover': {
                        bg: '#e4e9f2',
                    },
                    ':focus': {
                        backgroundColor: '#e4e9f2',
                    },
                    ':active': {
                        bg: '#c5cee0',
                    },
                },
                teal: {
                    color: 'white',
                    bg: '#01b4c0',
                    ':hover': {
                        bg: '#00939d',
                    },
                    ':focus': {
                        backgroundColor: '#01b4c0',
                    },
                    ':active': {
                        bg: '#00484d',
                    },
                },
                // // 4. We can override existing variants
                // solid: (props) => ({
                //     bg: props.colorMode === 'dark' ? 'red.300' : 'red.500',
                // }),
            },
        },
        Box: {
            variants: {
                'chart-card': {
                    width: 720,
                    height: 360,
                    backgroundColor: 'green',
                },
            },
        },
        Text: {
            variants: {
                'profile-title': {
                    alignSelf: 'start',
                    marginTop: '16px',
                    fontWeight: 'bold',
                },
            },
        },
        Menu: {
            parts: ['menu', 'item', 'list'],
            baseStyle: {
                list: {
                    border: 'none',
                    boxShadow: 'base',
                    padding: '16px',
                    borderRadius: '16px',
                },
                item: {
                    fontWeight: 'bold',
                    fontSize: '16px',
                    rounded: 'md',
                    height: '48px',
                    color: 'colors.black',
                    textTransform: 'Capitalize',
                    _hover: {
                        color: 'white',
                        bg: '#0a58ce',
                    },
                    _active: {
                        color: 'colors.white',
                        bg: '#0a58ce',
                    },
                    _focus: {
                        color: 'colors.white',
                        bg: '#edf1f7',
                    },
                },
            },
        },
        Tooltip: {
            baseStyle: {
                color: 'white',
                backgroundColor: 'pink.500',
            },
        },
        Popover: {
            baseStyle: {
                popper: {
                    border: 'none',
                    boxShadow: 'base',
                    borderRadius: '16px',
                },
                content: {
                    padding: '8px',
                    border: 'none',
                },
            },
        },
        Input: {
            variants: {
                unstyled: {
                    field: {
                        borderRadius: '0px',
                    },
                },
            },
        },
        Textarea: {
            variants: {
                unstyled: {
                    field: {
                        borderRadius: '0px',
                    },
                },
            },
        },
    },
})
