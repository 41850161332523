import './baseStyles.css'
import { ColorModeScript } from '@chakra-ui/react'
import React, { StrictMode } from 'react'
import ReactDOM from 'react-dom'
import Router from './Router'
import { BrowserRouter } from 'react-router-dom'

if (process.env.NODE_ENV !== 'development') {
    console.log = () => {}
}

ReactDOM.render(
    <StrictMode>
        <BrowserRouter>
            {/* <ColorModeScript /> */}
            <Router />
        </BrowserRouter>
    </StrictMode>,
    document.getElementById('root')
)
