import React from 'react'
import { Flex, Avatar, Text } from '@chakra-ui/react'

export default function CallingCard({ assistant }) {
    return (
        <Flex alignItems="center" display={{ base: 'none', md: 'flex' }}>
            <Flex display={{ sm: 'none', md: 'flex' }}>
                <Avatar
                    name={assistant.name}
                    bg="blue.500"
                    fontWeight="normal"
                    color="white"
                    cursor="pointer"
                    size="lg"
                    src={assistant.profilePhotoUrl}
                ></Avatar>
            </Flex>
            <Flex flexDir="column" justifyContent="center" ml="16px">
                <Text fontSize="xl" fontWeight="bold">
                    {assistant.name}
                </Text>
                {(assistant.jobTitle || assistant.company) && (
                    <Flex>
                        {assistant.jobTitle}{' '}
                        {assistant.company && (
                            <Text ml="4px"> | {assistant.company}</Text>
                        )}
                    </Flex>
                )}
            </Flex>
        </Flex>
    )
}
