import React, { useEffect, useState, useRef } from 'react'
import { Formik, Form } from 'formik'
import autosize from 'autosize'
import { Textarea, Text, Box } from '@chakra-ui/react'

export default function Description({
    expanded,
    description,
    handleUpdate,
    disabled,
}) {
    const [isEditing, setIsEditing] = useState(false)

    return (
        <>
            {!expanded && (
                <Text
                    style={{
                        whiteSpace: 'nowrap',
                        '&:hover': {
                            cursor: 'text !important',
                        },

                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        marginRight: 'auto',
                        pointerEvents: 'none',
                        fontSize: '18px',
                        marginLeft: '16px',
                    }}
                    w={{
                        base: '100%',
                        sm: '100%',
                        md: '90%',
                    }}
                >
                    {description}
                </Text>
            )}

            {expanded &&
                (!isEditing || disabled ? (
                    <Text
                        style={{
                            fontSize: 18,
                            minHeight: '54px',
                            whiteSpace: 'pre-wrap',
                            marginLeft: '16px',
                            width: '100%',
                        }}
                        onClick={() => setIsEditing(true)}
                    >
                        {description}
                    </Text>
                ) : (
                    <DescriptionForm
                        description={description}
                        handleUpdate={handleUpdate}
                        setIsEditing={setIsEditing}
                    />
                ))}
        </>
    )
}

const DescriptionForm = ({ description, handleUpdate, setIsEditing }) => {
    const ref = useRef()
    useEffect(() => {
        autosize(ref.current)
        return () => {
            autosize.destroy(ref.current)
        }
    }, [])
    return (
        <Formik
            enableReinitialize
            initialValues={{ description: description }}
            onSubmit={(values) => {
                setIsEditing(false)
                handleUpdate(values, 'description')
            }}
        >
            {(props) => (
                <Form
                    style={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        marginLeft: '16px',
                    }}
                >
                    <Textarea
                        name="description"
                        placeholder="Start typing to fill out the description"
                        variant="unstyled"
                        onChange={props.handleChange}
                        onBlur={props.handleSubmit}
                        value={props.values.description}
                        onSubmit={props.handleSubmit}
                        fontSize={18}
                        autoFocus
                        onKeyDown={(e) => {
                            Number(e.keyCode) === 13 &&
                                e.shiftKey === false &&
                                props.handleSubmit()

                            if (e.keyCode === 51 && e.shiftKey) {
                                props.handleSubmit()
                            }
                        }}
                        autoComplete="off"
                        transition="none"
                        lineHeight="base"
                        padding="0px"
                        borderRadius="0px"
                        minHeight="0px"
                        ref={ref}
                    />
                </Form>
            )}
        </Formik>
    )
}
