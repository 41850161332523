import React from 'react'
import { Logo } from './Navbar/Logo'
import { Flex } from '@chakra-ui/react'
import LeftNavButton from './LeftNavButton'

export default function Sidebar({ section, setSection, numSubmittedTasks }) {
    return (
        <Flex direction="column">
            <Flex direction="column">
                <LeftNavButton
                    selected={section === 0}
                    left="📥"
                    text="Submit a Task"
                    handleClick={() => setSection(0)}
                ></LeftNavButton>
                <hr style={{ marginTop: '8px', marginBottom: '8px' }} />
                <LeftNavButton
                    selected={section === 1}
                    left="✅"
                    text="Submitted Tasks"
                    right={numSubmittedTasks}
                    handleClick={() => setSection(1)}
                ></LeftNavButton>
            </Flex>
            <Flex mt="30vh" flexDirection="column" pl="32px" pr="32px">
                <Logo></Logo>
                <Flex fontWeight="bold">
                    office otter is a task management tool that integrates with
                    your email, texts, and Slack while providing on demand
                    Performance Reports.
                </Flex>
                <Flex
                    mt="24px"
                    fontWeight="bold"
                    textDecoration="underline"
                    onClick={() =>
                        window
                            .open('https://www.officeotter.com', '_blank')
                            .focus()
                    }
                    _hover={{ cursor: 'pointer' }}
                >
                    Try office otter for free
                </Flex>
            </Flex>
        </Flex>
    )
}
