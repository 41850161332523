import React from 'react'
import Navbar from './Navbar/Navbar'
import { Container, Grid, GridItem, useBreakpointValue } from '@chakra-ui/react'

export default function MainLayout({ left, main, mobileMenu }) {
    const sideNavColumns = useBreakpointValue({ base: 0, lg: 3 })
    const contentNavColumns = useBreakpointValue({ base: 12, lg: 9 })
    return (
        <div>
            <Navbar mobileMenu={mobileMenu} />
            <Container maxW="container.xl">
                <Grid
                    templateRows="repeat(1, 1fr)"
                    templateColumns="repeat(12, 1fr)"
                >
                    <GridItem
                        colSpan={sideNavColumns}
                        display={{ base: 'none', lg: 'flex' }}
                        mr="24px"
                    >
                        {left}
                    </GridItem>
                    <GridItem colSpan={contentNavColumns} mb="32px">
                        {main}
                    </GridItem>
                </Grid>
            </Container>
        </div>
    )
}
