import React from 'react'
import { setMinutes, setHours, format } from 'date-fns'
import { SingleDatepicker } from 'chakra-dayzed-datepicker'

export default function DatePicker({ date, setDate, isDisabled }) {
    return (
        <SingleDatepicker
            name="date"
            date={date}
            onDateChange={setDate}
            propsConfigs={{
                dateNavBtnProps: {
                    colorScheme: 'blue',
                    color: 'black',
                },
                dayOfMonthBtnProps: {
                    colorScheme: 'blue',
                    color: 'black',
                    selectedBg: 'blue.200',
                    _hover: {
                        bg: 'blue.500',
                        color: 'white',
                    },
                    padding: 0,
                },
                inputProps: {
                    size: 'md',
                    value: format(date, 'MM-dd-yyyy'),
                    onChange: setDate,
                    className: 'hover',
                    isDisabled,
                    onKeyDown: (e) => {
                        e.preventDefault()
                        return false
                    },
                },
            }}
            className="hover"
        />
    )
}
