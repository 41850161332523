import React from 'react'
import { Flex, Text, Box, VStack, Tooltip } from '@chakra-ui/react'
import InboxCard from './InboxCard'

export default function Submitted({ assistant, submittedTasks }) {
    return (
        <Flex flexDir="column" paddingLeft="32px">
            <Flex>
                {assistant && (
                    <Text fontSize="xl" fontWeight="bold">
                        Tasks you have sent to {assistant.name.split(' ')[0]}
                    </Text>
                )}
            </Flex>
            <Flex>
                {assistant && (
                    <>
                        {submittedTasks.length === 0 ? (
                            <Text>
                                Looks like you haven't submitted a task to{' '}
                                {assistant.name.split(' ')[0]} yet. <br /> Go to
                                your{' '}
                                <span style={{ fontWeight: 'bold' }}>
                                    Submit A Task
                                </span>{' '}
                                section to send your first task!
                            </Text>
                        ) : (
                            <Box
                                pl="8px"
                                pr="8px"
                                pb="8px"
                                ml="-8px"
                                mr="-8px"
                                maxH="75vh"
                                overflow="auto"
                                width="80%"
                            >
                                <Flex
                                    justify="end"
                                    p="0px 16px"
                                    display={{
                                        base: 'none',
                                        md: 'flex',
                                    }}
                                >
                                    <Text fontWeight="bold" color="#8f9bb3">
                                        Due
                                    </Text>
                                    <Text
                                        fontWeight="bold"
                                        color="#8f9bb3"
                                        ml="64px"
                                    >
                                        Submitted
                                    </Text>
                                </Flex>
                                <VStack id="submitted-task-list">
                                    <Box
                                        style={{
                                            width: '100%',
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Tooltip
                                            label="Waiting for user to accept or deny"
                                            placement="top"
                                        >
                                            <Flex
                                                alignItems="center"
                                                marginRight="16px"
                                            >
                                                <span
                                                    style={{
                                                        paddingRight: 8,
                                                        fontSize: 22,
                                                    }}
                                                >
                                                    {'⏳'}
                                                </span>
                                                <Text
                                                    color="blue.500"
                                                    fontSize="md"
                                                    fontWeight="bold"
                                                    whiteSpace="nowrap"
                                                    marginLeft="8px"
                                                >
                                                    {'Pending'}
                                                </Text>
                                            </Flex>
                                        </Tooltip>
                                        <hr
                                            style={{
                                                height: 1,
                                                backgroundColor: '#E5ECF6',
                                                border: '1px solid #E5ECF6',
                                                width: '100%',
                                                borderRadius: 16,
                                            }}
                                        />
                                    </Box>
                                    {submittedTasks
                                        ?.filter(
                                            (task) =>
                                                task.status === 'requested'
                                        )
                                        .map((task, index) => (
                                            <InboxCard
                                                open={false}
                                                due={task.due}
                                                files={task.files}
                                                description={task.description}
                                                submittedDate={task.createdDate}
                                                submittedSection={true}
                                            />
                                        ))}
                                    <Box
                                        style={{
                                            width: '100%',
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Tooltip
                                            label="User has accepted and is working on"
                                            placement="top"
                                        >
                                            <Flex
                                                alignItems="center"
                                                marginRight="16px"
                                            >
                                                <span
                                                    style={{
                                                        paddingRight: 8,
                                                        fontSize: 22,
                                                    }}
                                                >
                                                    {'👩‍💻'}
                                                </span>
                                                <Text
                                                    color="blue.500"
                                                    fontSize="md"
                                                    fontWeight="bold"
                                                    whiteSpace="nowrap"
                                                    marginLeft="8px"
                                                >
                                                    {'In Progress'}
                                                </Text>
                                            </Flex>
                                        </Tooltip>
                                        <hr
                                            style={{
                                                height: 1,
                                                backgroundColor: '#E5ECF6',
                                                border: '1px solid #E5ECF6',
                                                width: '100%',
                                                borderRadius: 16,
                                            }}
                                        />
                                    </Box>
                                    {submittedTasks
                                        ?.filter(
                                            (task) => task.status === 'accepted'
                                        )
                                        .map((task, index) => (
                                            <InboxCard
                                                open={false}
                                                due={task.due}
                                                files={task.files}
                                                description={task.description}
                                                submittedDate={task.createdDate}
                                                submittedSection={true}
                                            />
                                        ))}
                                    <Box
                                        style={{
                                            width: '100%',
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Tooltip
                                            label="User has completed"
                                            placement="top"
                                        >
                                            <Flex
                                                alignItems="center"
                                                marginRight="16px"
                                            >
                                                <span
                                                    style={{
                                                        paddingRight: 8,
                                                        fontSize: 22,
                                                    }}
                                                >
                                                    {'✅'}
                                                </span>
                                                <Text
                                                    color="blue.500"
                                                    fontSize="md"
                                                    fontWeight="bold"
                                                    whiteSpace="nowrap"
                                                    marginLeft="8px"
                                                >
                                                    {'Completed'}
                                                </Text>
                                            </Flex>
                                        </Tooltip>
                                        <hr
                                            style={{
                                                height: 1,
                                                backgroundColor: '#E5ECF6',
                                                border: '1px solid #E5ECF6',
                                                width: '100%',
                                                borderRadius: 16,
                                            }}
                                        />
                                    </Box>
                                    {submittedTasks
                                        ?.filter(
                                            (task) =>
                                                task.status === 'completed'
                                        )
                                        .map((task, index) => (
                                            <InboxCard
                                                open={false}
                                                due={task.due}
                                                files={task.files}
                                                description={task.description}
                                                submittedDate={task.createdDate}
                                                submittedSection={true}
                                            />
                                        ))}
                                    <Box
                                        style={{
                                            width: '100%',
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Tooltip
                                            label="User denied"
                                            placement="top"
                                        >
                                            <Flex
                                                alignItems="center"
                                                marginRight="16px"
                                            >
                                                <span
                                                    style={{
                                                        paddingRight: 8,
                                                        fontSize: 22,
                                                    }}
                                                >
                                                    {'❌'}
                                                </span>
                                                <Text
                                                    color="blue.500"
                                                    fontSize="md"
                                                    fontWeight="bold"
                                                    whiteSpace="nowrap"
                                                    marginLeft="8px"
                                                >
                                                    {'Denied'}
                                                </Text>
                                            </Flex>
                                        </Tooltip>
                                        <hr
                                            style={{
                                                height: 1,
                                                backgroundColor: '#E5ECF6',
                                                border: '1px solid #E5ECF6',
                                                width: '100%',
                                                borderRadius: 16,
                                            }}
                                        />
                                    </Box>
                                    {submittedTasks
                                        ?.filter(
                                            (task) => task.status === 'denied'
                                        )
                                        .map((task, index) => (
                                            <InboxCard
                                                open={false}
                                                due={task.due}
                                                files={task.files}
                                                description={task.description}
                                                submittedDate={task.createdDate}
                                                submittedSection={true}
                                            />
                                        ))}
                                </VStack>
                            </Box>
                        )}
                    </>
                )}
            </Flex>
        </Flex>
    )
}
