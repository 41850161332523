import React, { useState } from 'react'
import {
    Box,
    Flex,
    VStack,
    Progress,
    Text,
    Button,
    IconButton,
    Collapse,
    Spacer,
} from '@chakra-ui/react'
import { CarrotIcon } from './ChakraDesign/Icons'
import { format } from 'date-fns'
import Description from './Description'
import AttachmentsList from './AttachmentsList'
import InboxActionsBar from './InboxActionsBar'

export default function InboxCard({
    open,
    due,
    setDue,
    files,
    setFiles,
    description,
    submittedDate,
    submittedSection,
    setDescription,
    canEditDescription,
}) {
    const [expanded, setExpanded] = useState(open)

    const handleClickTask = () => {
        if (!expanded) {
            setExpanded(true)
        }
    }

    const handleCloseTask = (e) => {
        e.preventDefault()
        e.stopPropagation()
        setExpanded(false)
    }

    const addDueDate = (due) => {
        setDue(due)
    }

    const isOverDue = (due) => {
        if (due) {
            return new Date(due) < new Date()
        }
    }

    return (
        <Box
            w="100%"
            _hover={{
                boxShadow: '0 8px 16px 0 rgba(56, 96, 165, 0.15)',
                transition: '0.3s',
            }}
            boxShadow={`${expanded && '0 8px 16px 0 rgba(56, 96, 165, 0.15)'}`}
            onClick={handleClickTask}
            backgroundColor="colors.white"
            cursor="pointer"
            mt="16px"
            p="16px"
            borderRadius="8px"
        >
            <Flex
                id="inbox-top-bar"
                justifyContent={{ base: 'start', md: 'space-between' }}
                minHeight="48px"
            >
                <Box
                    style={{
                        paddingRight: '16px',
                        alignSelf: 'center',
                        marginLeft: '-4px',
                        marginTop: expanded && '11px',
                    }}
                    w={{
                        base: '90%',
                        md: !expanded ? '50%' : '100%',
                    }}
                >
                    <VStack flexDirection={expanded ? 'row' : 'column'}>
                        <Description
                            expanded={expanded}
                            disabled={!canEditDescription}
                            description={description}
                            handleUpdate={(d) => setDescription(d.description)}
                        />
                    </VStack>
                </Box>
                {!expanded && (
                    <Flex justify="end">
                        <Text
                            fontSize="normal"
                            style={{
                                marginRight: 'auto',
                                alignSelf: 'center',
                                marginRight: '32px',
                            }}
                            color={isOverDue(due) ? 'red.500' : 'colors.black'}
                            w={{
                                base: 'none',
                                md: '136px',
                            }}
                            display={{
                                base: 'none',
                                md: 'flex',
                            }}
                        >
                            {due && format(new Date(due), 'MMM d, h:mma')}
                        </Text>
                        <Text
                            fontSize="normal"
                            style={{
                                alignSelf: 'center',
                            }}
                            w={{
                                md: '58px',
                            }}
                            display={{
                                base: 'none',
                                md: 'flex',
                            }}
                        >
                            {submittedSection &&
                                format(new Date(submittedDate), 'MMM d')}
                        </Text>
                    </Flex>
                )}
                {expanded && (
                    <IconButton
                        icon={<CarrotIcon />}
                        onClick={handleCloseTask}
                        size="lg"
                        borderRadius="xl"
                        backgroundColor="transparent"
                        color="grey.900"
                        alignSelf="flex-start"
                    />
                )}
            </Flex>
            <Collapse in={expanded} unmountOnExit>
                <Box
                    id="inbox-card-content"
                    marginLeft="32px"
                    marginRight="32px"
                    marginTop="8px"
                ></Box>
                {files && (
                    <AttachmentsList attachments={files} cantDelete={true} />
                )}
                <InboxActionsBar
                    due={due}
                    addDueDate={addDueDate}
                    files={files}
                    setFiles={setFiles}
                    submittedSection={submittedSection}
                    isOverDue={isOverDue}
                />
            </Collapse>
        </Box>
    )
}
