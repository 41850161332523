import React, { useContext } from 'react'
import {
    Flex,
    Center,
    Avatar,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    useDisclosure,
    Text,
    Button,
    Box,
    Modal,
    ModalOverlay,
    ModalBody,
    ModalFooter,
    ModalHeader,
    ModalCloseButton,
    ModalContent,
    Progress,
    VStack,
    Image,
    Input,
    FormControl,
    FormLabel,
    FormHelperText,
    useColorModeValue,
    useToast,
    IconButton,
    Switch,
    CircularProgress,
} from '@chakra-ui/react'
import { SubmitIcon, LeftArrow, CircleCheckIcon } from '../ChakraDesign/Icons'
import { Formik, Form } from 'formik'
import { Logo } from './Logo'
import {
    TasksIcon,
    ProfileIcon,
    ReportsIcon,
    SignOutIcon,
    MyHubIcon,
} from '../ChakraDesign/Icons'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { ExecutiveContext } from '../Contexts/ExecutiveContext'
import { AssistantContext } from '../Contexts/AssistantContext'
import { setTokenHeader, apiCall } from '../Util/api'
import CallingCard from '../CallingCard'

export default function Navbar({ mobileMenu }) {
    const { executive, setExecutive } = useContext(ExecutiveContext)
    const { assistant, setAssistant } = useContext(AssistantContext)
    const location = useLocation()
    const navigate = useNavigate()
    const { isOpen, onOpen, onClose } = useDisclosure()

    const handleSignOut = () => {
        localStorage.removeItem('inboxJwtToken')
        setExecutive(null)
        setTokenHeader(null)
        window.location = '/'
    }

    const signIn = async (values) => {
        //create user
        try {
            let userData = await apiCall(
                'POST',
                '/auth/requester/signin',
                values
            )
            let token = userData.token
            localStorage.setItem('inboxJwtToken', userData.token)

            setTokenHeader(token)
            setExecutive(userData.user)
            onClose()
        } catch (err) {
            alert(JSON.stringify(err))
        }
    }

    return (
        <Flex justifyContent="center" w="100%" mb="32px">
            <Flex
                h="96px"
                w="100%"
                maxW="container.xl"
                justifyContent="space-between"
                alignItems="center"
                pl="20px"
                pr="24px"
            >
                <CallingCard assistant={assistant}></CallingCard>
                <Center
                    display={{ base: 'flex', lg: 'none' }}
                    alignSelf="center"
                >
                    {mobileMenu}
                </Center>
                {executive.name ? (
                    <Center ml="16px">
                        <Menu>
                            <MenuButton>
                                <Avatar
                                    name={executive.name}
                                    bg="blue.500"
                                    fontWeight="normal"
                                    color="white"
                                />
                            </MenuButton>
                            <MenuList>
                                {/* <Link to="/userprofile/info">
                                    <MenuItem icon={<ProfileIcon />}>
                                        Profile
                                    </MenuItem>
                                </Link> */}
                                <MenuItem
                                    icon={<SignOutIcon />}
                                    onClick={handleSignOut}
                                >
                                    Sign Out
                                </MenuItem>
                            </MenuList>
                        </Menu>
                    </Center>
                ) : (
                    <Center>
                        <Button size="lg" onClick={onOpen}>
                            Sign In
                        </Button>
                    </Center>
                )}
            </Flex>
            <Modal onClose={onClose} size="full" isOpen={isOpen}>
                <ModalOverlay />
                <ModalContent display="flex" alignItems="center">
                    <ModalHeader maxWidth="880px">
                        <Text fontSize="xl" fontWeight="bold">
                            Sign In to Office Otter
                        </Text>
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Box
                            w="100%"
                            boxShadow="0 8px 16px 0 rgba(56, 96, 165, 0.15)"
                            backgroundColor="colors.white"
                            cursor="pointer"
                            mt="16px"
                            p="24px"
                            borderRadius="8px"
                        >
                            <Flex direction="column">
                                <Formik
                                    initialValues={{
                                        email: '',
                                        password: '',
                                    }}
                                    onSubmit={async (values, actions) => {
                                        signIn(values)
                                    }}
                                >
                                    {({
                                        values,
                                        handleChange,
                                        isSubmitting,
                                    }) => (
                                        <Form
                                            style={{
                                                width: '360px',
                                            }}
                                        >
                                            <FormControl id="email" isRequired>
                                                <FormLabel>
                                                    Email address
                                                </FormLabel>
                                                <Input
                                                    type="email"
                                                    size="lg"
                                                    variant="filled"
                                                    placeholder="email"
                                                    onChange={handleChange}
                                                    value={values.email}
                                                    name="email"
                                                />
                                            </FormControl>
                                            <FormControl
                                                id="password"
                                                isRequired
                                            >
                                                <FormLabel mt="16px">
                                                    Password
                                                </FormLabel>
                                                <Input
                                                    type="password"
                                                    size="lg"
                                                    variant="filled"
                                                    placeholder="iloveotters"
                                                    onChange={handleChange}
                                                    value={values.password}
                                                    name="password"
                                                />
                                            </FormControl>
                                            <Button
                                                colorScheme="blue"
                                                variant="solid"
                                                size="lg"
                                                width="100%"
                                                marginTop="24px !important"
                                                type="submit"
                                            >
                                                {isSubmitting ? (
                                                    <CircularProgress
                                                        isIndeterminate
                                                        size="32px"
                                                    />
                                                ) : (
                                                    'Sign in'
                                                )}
                                            </Button>
                                        </Form>
                                    )}
                                </Formik>
                            </Flex>
                        </Box>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </Flex>
    )
}
