import React, { useEffect, useState, useContext } from 'react'
import { apiCall } from './Util/api'
import {
    Flex,
    Text,
    Button,
    Box,
    Modal,
    ModalOverlay,
    ModalBody,
    ModalFooter,
    ModalHeader,
    ModalCloseButton,
    ModalContent,
    Progress,
    VStack,
    Image,
    Input,
    FormControl,
    FormLabel,
    FormHelperText,
    useColorModeValue,
    useToast,
    IconButton,
    Switch,
    CircularProgress,
    useDisclosure,
} from '@chakra-ui/react'
import CreateTask from './CreateTask'
import { SubmitIcon, LeftArrow, CircleCheckIcon } from './ChakraDesign/Icons'
import { WarningIcon } from '@chakra-ui/icons'
import { AssistantContext } from './Contexts/AssistantContext'
import { ExecutiveContext } from './Contexts/ExecutiveContext'
import { Formik, Form } from 'formik'
import ToastyBoi from './ToastyBoi'

//hook up button to state and submit to backend
export default function Inbox({ submittedTasks, setSubmittedTasks }) {
    //some state to represent the step of the current task
    const { assistant, setAssistant } = useContext(AssistantContext)
    const { executive, setExecutive } = useContext(ExecutiveContext)
    const [submissionStep, setSubmissionStep] = useState(0)
    const [description, setDescription] = useState('')
    const [files, setFiles] = useState([])
    const [due, setDue] = useState(null)
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [isChecked, setIsChecked] = useState(false)
    const toaster = useToast()
    //api call to create the task
    const createTask = async (userInfo) => {
        try {
            const response = await apiCall(
                'POST',
                `/requesters/${executive._id}/tasks`,
                {
                    description,
                    files,
                    due,
                    shouldNotifyRequester: isChecked,
                    user: assistant._id,
                    executiveInfo: {
                        ...userInfo,
                    },
                }
            )

            if (response.executive) {
                setExecutive(response.executive)
            }

            //send toasty boi
            toaster({
                status: 'success',
                duration: 3000,
                isClosable: true,
                render: () => (
                    <ToastyBoi
                        message={'Task Submitted'}
                        icon={<CircleCheckIcon fill="white" />}
                        backgroundColor="blue.500"
                    />
                ),
                position: 'top',
            })

            let newTasks = [...submittedTasks]
            newTasks.unshift(response.task)
            setSubmittedTasks(newTasks)
        } catch (error) {
            console.log(error)
            if (error.status === 500) {
                //send toasty boi
                //send toasty boi
                toaster({
                    status: 'error',
                    duration: 6000,
                    isClosable: true,
                    position: 'top',
                    render: () => (
                        <ToastyBoi
                            message={
                                'A user with this emails already exists, please sign in on the top right and try again.'
                            }
                            icon={<WarningIcon fill="white" />}
                            backgroundColor="#ff3d71"
                        />
                    ),
                })
            } else {
                alert(JSON.stringify(error))
            }
        }
        setSubmissionStep(0)
        setDescription('')
        setFiles([])
        setDue(null)
        setIsChecked(false)
    }
    //update to submitted task after creating

    //api call to update the user if/when they put in their info
    return (
        assistant && (
            <Flex
                flexDir="column"
                paddingLeft={{ lg: '32px' }}
                paddingRight={{ lg: '256px' }}
            >
                <Flex>
                    {submissionStep < 2 && (
                        <Text fontSize="xl" fontWeight="bold">
                            Submit a task to {assistant.name.split(' ')[0]}'s
                            Inbox
                        </Text>
                    )}
                    {submissionStep === 2 && (
                        <>
                            <Text fontSize="xl" fontWeight="bold">
                                Want to add anything else to this task?
                            </Text>
                        </>
                    )}
                </Flex>
                <Flex flexDirection="column">
                    <Box width="100%">
                        <CreateTask
                            placeholder={`Create a new task for ${
                                assistant.name.split(' ')[0]
                            }`}
                            submissionStep={submissionStep}
                            setSubmissionStep={setSubmissionStep}
                            description={description}
                            setDescription={setDescription}
                            due={due}
                            setDue={setDue}
                            files={files}
                            setFiles={setFiles}
                            executive={executive}
                        />
                    </Box>
                    {submissionStep === 2 && (
                        <>
                            {executive.name && (
                                <FormControl
                                    display="flex"
                                    alignItems="center"
                                    mt="16px"
                                >
                                    <Switch
                                        id="email-alerts"
                                        size="lg"
                                        mr="16px"
                                        isChecked={isChecked}
                                        onChange={() => {
                                            setIsChecked(!isChecked)
                                        }}
                                    />
                                    <FormLabel htmlFor="email-alerts" mb="0">
                                        Notify me by email when this task is
                                        completed.
                                    </FormLabel>
                                </FormControl>
                            )}

                            <Button
                                rightIcon={<SubmitIcon />}
                                variant="teal"
                                size="lg"
                                mt="24px"
                                alignSelf="flex-end"
                                onClick={() => {
                                    if (!executive.name) {
                                        onOpen()
                                        setSubmissionStep(3)
                                    } else {
                                        createTask()
                                    }
                                }}
                            >
                                Submit Task
                            </Button>
                        </>
                    )}
                </Flex>
                {submissionStep === 3 && (
                    <Modal
                        onClose={() => {
                            setSubmissionStep(0)
                            setDescription('')
                            setFiles([])
                            setDue(null)
                            setIsChecked(false)
                            onClose()
                        }}
                        size="full"
                        isOpen={isOpen}
                    >
                        <ModalOverlay />
                        <ModalContent display="flex" alignItems="center">
                            <ModalHeader maxWidth="880px">
                                <Text fontSize="xl" fontWeight="bold">
                                    Add your information to complete your
                                    submission
                                </Text>
                                <Progress
                                    value={100}
                                    colorScheme="blue"
                                    borderRadius="16px"
                                    height="8px"
                                    width="100%"
                                    marginTop="8px"
                                    marginBottom="8px"
                                />
                            </ModalHeader>
                            <ModalCloseButton />
                            <ModalBody>
                                <Box
                                    w="100%"
                                    boxShadow="0 8px 16px 0 rgba(56, 96, 165, 0.15)"
                                    backgroundColor="colors.white"
                                    cursor="pointer"
                                    mt="16px"
                                    p="24px"
                                    borderRadius="8px"
                                >
                                    <Flex direction="column">
                                        <Flex
                                            justifyContent="space-between"
                                            alignItems="center"
                                            marginBottom="32px"
                                        >
                                            <IconButton
                                                icon={<LeftArrow />}
                                            ></IconButton>
                                            <Text
                                                fontSize="lg"
                                                fontWeight="bold"
                                            >
                                                Submit Task
                                            </Text>
                                            <Box width="48px"></Box>
                                        </Flex>
                                        <Formik
                                            initialValues={{
                                                name: '',
                                                email: '',
                                                jobTitle: '',
                                                password: '',
                                            }}
                                            onSubmit={async (
                                                values,
                                                actions
                                            ) => {
                                                createTask(values)
                                            }}
                                        >
                                            {({
                                                values,
                                                handleChange,
                                                isSubmitting,
                                            }) => (
                                                <Form
                                                    style={{
                                                        width: '360px',
                                                    }}
                                                >
                                                    <FormControl
                                                        id="name"
                                                        isRequired
                                                    >
                                                        <FormLabel>
                                                            Name
                                                        </FormLabel>
                                                        <Input
                                                            type="text"
                                                            size="lg"
                                                            variant="filled"
                                                            placeholder="name"
                                                            onChange={
                                                                handleChange
                                                            }
                                                            value={values.name}
                                                            name="name"
                                                        />
                                                    </FormControl>
                                                    <FormControl
                                                        id="email"
                                                        isRequired
                                                    >
                                                        <FormLabel mt="16px">
                                                            Email address
                                                        </FormLabel>
                                                        <Input
                                                            type="email"
                                                            size="lg"
                                                            variant="filled"
                                                            placeholder="email"
                                                            onChange={
                                                                handleChange
                                                            }
                                                            value={values.email}
                                                            name="email"
                                                        />
                                                    </FormControl>
                                                    <FormControl
                                                        id="job-title"
                                                        isRequired
                                                    >
                                                        <FormLabel mt="16px">
                                                            Job Title
                                                        </FormLabel>
                                                        <Input
                                                            type="text"
                                                            size="lg"
                                                            variant="filled"
                                                            placeholder="job title"
                                                            onChange={
                                                                handleChange
                                                            }
                                                            value={
                                                                values.jobTitle
                                                            }
                                                            name="jobTitle"
                                                        />
                                                    </FormControl>
                                                    <FormControl
                                                        id="password"
                                                        isRequired
                                                    >
                                                        <FormLabel mt="16px">
                                                            Password
                                                        </FormLabel>
                                                        <Input
                                                            type="password"
                                                            size="lg"
                                                            variant="filled"
                                                            placeholder="iloveotters"
                                                            onChange={
                                                                handleChange
                                                            }
                                                            value={
                                                                values.password
                                                            }
                                                            name="password"
                                                        />
                                                    </FormControl>
                                                    <FormControl
                                                        display="flex"
                                                        alignItems="center"
                                                        mt="16px"
                                                    >
                                                        <Switch
                                                            id="email-alerts"
                                                            size="lg"
                                                            mr="16px"
                                                            isChecked={
                                                                isChecked
                                                            }
                                                            onChange={() => {
                                                                setIsChecked(
                                                                    !isChecked
                                                                )
                                                            }}
                                                        />
                                                        <FormLabel
                                                            htmlFor="email-alerts"
                                                            mb="0"
                                                        >
                                                            Notify me by email
                                                            when this task is
                                                            completed.
                                                        </FormLabel>
                                                    </FormControl>
                                                    <Button
                                                        colorScheme="blue"
                                                        variant="solid"
                                                        size="lg"
                                                        width="100%"
                                                        marginTop="24px !important"
                                                        marginBottom="24px !important"
                                                        type="submit"
                                                    >
                                                        {isSubmitting ? (
                                                            <CircularProgress
                                                                isIndeterminate
                                                                size="32px"
                                                            />
                                                        ) : (
                                                            'Submit task'
                                                        )}
                                                    </Button>
                                                </Form>
                                            )}
                                        </Formik>
                                    </Flex>
                                </Box>
                            </ModalBody>
                        </ModalContent>
                    </Modal>
                )}
            </Flex>
        )
    )
}
