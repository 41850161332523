import React from 'react'
import { Button, Flex, Box } from '@chakra-ui/react'

export default function LeftNavButton({
    selected,
    left,
    text,
    right,
    handleClick,
}) {
    return (
        <Button
            variant="left-nav-button"
            bg={`${selected && 'blue.50'}`}
            textColor={`${selected && 'blue.500'}`}
            onClick={handleClick}
            maxWidth="none"
        >
            <Flex justifyContent="space-between" width="100%">
                <Box fontSize="22px" alignSelf="center">
                    {left}
                </Box>
                <Box
                    mr="auto"
                    ml={`${left.length > 0 && '16px'}`}
                    alignSelf="center"
                >
                    {' '}
                    {text}
                </Box>
                <Box
                    textColor={selected ? 'blue.500' : 'grey.900'}
                    alignSelf="center"
                >
                    {right}
                </Box>
            </Flex>
        </Button>
    )
}
