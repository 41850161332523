import React, { useMemo, useState, useEffect } from 'react'
import { ChakraProvider, useToast } from '@chakra-ui/react'
import theme from './ChakraDesign/theme'
import {
    Routes,
    Route,
    Navigate,
    useParams,
    useLocation,
} from 'react-router-dom'
import { ExecutiveContext } from './Contexts/ExecutiveContext'
import jwtDecode from 'jwt-decode'
import { apiCall, setTokenHeader } from './Util/api'
import MainLayout from './MainLayout'
import Inbox from './Inbox'
import Sidebar from './Sidebar'
import { AssistantContext } from './Contexts/AssistantContext'
import Submitted from './Submitted'
import HomePage from './HomePage'
import ToastyBoi from './ToastyBoi'
import { WarningIcon } from '@chakra-ui/icons'
import MobileNav from './MobileNav'

//deploy
function App() {
    const [assistant, setAssistant] = useState(null)
    const [executive, setExecutive] = useState(null)
    const [submittedTasks, setSubmittedTasks] = useState([])
    const assistantProviderValue = useMemo(
        () => ({ assistant, setAssistant }),
        [assistant, setAssistant]
    )
    const executiveProviderValue = useMemo(
        () => ({ executive, setExecutive }),
        [executive, setExecutive]
    )
    const [isUserLoading, setIsUserLoading] = useState(true)
    const toaster = useToast()
    const [section, setSection] = useState(0)
    //get vanity link name from url
    let location = useLocation() // Unpacking and retrieve id

    useEffect(() => {
        if (location.pathname.length > 1) {
            checkToken()
            getAssistant()
        } else {
            setIsUserLoading(false)
        }
    }, [])

    useEffect(() => {
        if (executive && assistant) {
            getSubmittedTasks()
        }
    }, [executive, assistant])

    const checkToken = async () => {
        setIsUserLoading(true)
        //check for auth token and set user if found
        let token = localStorage.getItem('inboxJwtToken')
        let newUserData = null
        if (token) {
            setTokenHeader(token)
            const decoded = jwtDecode(token)
            newUserData = await apiCall('GET', `/requesters/${decoded._id}`)
        } else {
            //create user
            newUserData = await apiCall('POST', '/auth/requester')
            token = newUserData.token
            localStorage.setItem('inboxJwtToken', token)
        }
        setExecutive(newUserData)
        setTokenHeader(token)
        setIsUserLoading(false)
    }

    const getAssistant = async () => {
        try {
            let assistant = await apiCall(
                'GET',
                `/inbox/assistant${location.pathname}`
            )

            setAssistant(assistant)
        } catch (error) {
            setTimeout(() => {
                window.location = '/'
            }, 4000)

            //send toasty boi
            toaster({
                status: 'error',
                duration: 3000,
                isClosable: true,
                position: 'top',
                render: () => (
                    <ToastyBoi
                        message={
                            'No user found at that link, double check the spelling.'
                        }
                        icon={<WarningIcon fill="white" />}
                        backgroundColor="#ff3d71"
                    />
                ),
            })
        }
    }

    const getSubmittedTasks = async () => {
        try {
            const tasks = await apiCall(
                `GET`,
                `/requesters/${executive._id}/tasks/submitted/${assistant._id}`
            )
            const sortedTasks = tasks.sort(
                (a, b) => new Date(b.createdDate) - new Date(a.createdDate)
            )
            setSubmittedTasks(sortedTasks)
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <ChakraProvider theme={theme}>
            {isUserLoading ? (
                <span></span>
            ) : (
                <AssistantContext.Provider value={assistantProviderValue}>
                    <ExecutiveContext.Provider value={executiveProviderValue}>
                        <Routes>
                            <Route path="/" element={<HomePage />} />
                            <Route
                                path="/:vanitylink"
                                element={
                                    assistant && (
                                        <MainLayout
                                            left={
                                                <Sidebar
                                                    section={section}
                                                    setSection={setSection}
                                                    numSubmittedTasks={
                                                        submittedTasks.length
                                                    }
                                                />
                                            }
                                            mobileMenu={
                                                <MobileNav
                                                    section={section}
                                                    setSection={setSection}
                                                    numSubmittedTasks={
                                                        submittedTasks.length
                                                    }
                                                />
                                            }
                                            main={
                                                <>
                                                    {section === 0 && (
                                                        <Inbox
                                                            submittedTasks={
                                                                submittedTasks
                                                            }
                                                            setSubmittedTasks={
                                                                setSubmittedTasks
                                                            }
                                                        />
                                                    )}
                                                    {section === 1 && (
                                                        <Submitted
                                                            assistant={
                                                                assistant
                                                            }
                                                            submittedTasks={
                                                                submittedTasks
                                                            }
                                                        />
                                                    )}
                                                </>
                                            }
                                        />
                                    )
                                }
                            />
                            <Route path="*" element={<HomePage />} />
                        </Routes>
                    </ExecutiveContext.Provider>
                </AssistantContext.Provider>
            )}
        </ChakraProvider>
    )
}

export default App
