import React from 'react'
import { Icon, Box, Text } from '@chakra-ui/react'

export default function Icons() {
    return (
        <Box>
            <Text fontSize="3xl">Icons</Text>
            <CalendarIcon />
            <AddTaskIcon />
            <ReportsIcon />
            <AddTaskIcon />
            <ReportsIcon />
            <AttachmentIcon />
            <AttachmentSlantIcon />
            <CarrotIcon />
            <LabelIcon />
            <SharingIcon />
            <CalendarIcon />
            <KebabIcon />
            <MeatballIcon />
            <LoopIcon />
            <PencilIcon />
            <TemplateIcon />
            <TemplateNavIcon />
            <TrashIcon />
            <CopyIcon />
            <PlusIcon />
            <ProfileIcon />
            <TasksIcon />
            <SignOutIcon />
            <ActivityIcon />
            <DownloadIcon />
            <CloseIcon />
            <ClockIcon />
            <CircleCheckIcon />
            <FiltersIcon />
            <SearchIcon />
            <MyHubIcon />
        </Box>
    )
}

export const MyHubIcon = (props) => {
    return (
        <Icon {...props} width="24px" height="24px" viewBox="0 0 24 24">
            <path
                fill="currentColor"
                d="M14 13h-4c-.55 0-1 .45-1 1s.45 1 1 1h4c.55 0 1-.45 1-1s-.45-1-1-1m6 5.068c0 .238-.224.432-.5.432h-15c-.276 0-.5-.194-.5-.432V5.931c0-.238.224-.43.5-.43h4.126l2.6 3.177c.19.233.474.368.774.368h7.5c.276 0 .5.193.5.432v8.59zm-.5-11.022h-7.026L9.875 3.867c-.19-.233-.475-.367-.774-.367H4.5C3.122 3.5 2 4.59 2 5.931v12.137C2 19.41 3.122 20.5 4.5 20.5h15c1.378 0 2.5-1.09 2.5-2.432v-8.59c0-1.342-1.122-2.432-2.5-2.432z"
            />
        </Icon>
    )
}

export const FiltersIcon = (props) => {
    return (
        <Icon {...props} width="24px" height="24px" viewBox="0 0 24 24">
            <path
                fill="currentColor"
                d="M6 2c.553 0 1 .448 1 1v11.185c1.161.414 2 1.514 2 2.815 0 1.302-.839 2.402-2 2.816V21c0 .553-.447 1-1 1-.553 0-1-.447-1-1v-1.184C3.839 19.402 3 18.302 3 17c0-1.301.839-2.401 2-2.815V3c0-.552.447-1 1-1zm12 0c.553 0 1 .447 1 1v7.185c1.161.414 2 1.513 2 2.815s-.839 2.401-2 2.815V21c0 .553-.447 1-1 1-.553 0-1-.447-1-1v-5.185c-1.161-.414-2-1.513-2-2.815s.839-2.4 2-2.815V3c0-.553.447-1 1-1zm-6 0c1.654 0 3 1.346 3 3 0 1.302-.839 2.401-2 2.815V21c0 .553-.447 1-1 1-.553 0-1-.447-1-1V7.815C9.839 7.401 9 6.302 9 5c0-1.654 1.346-3 3-3z"
            />
        </Icon>
    )
}

export const SearchIcon = (props) => {
    return (
        <Icon {...props} width="24px" height="24px" viewBox="0 0 24 24">
            <path
                fill="currentColor"
                d="M5 11c0-3.309 2.691-6 6-6s6 2.691 6 6-2.691 6-6 6-6-2.691-6-6m15.707 8.293l-3.395-3.396C18.365 14.543 19 12.846 19 11c0-4.411-3.589-8-8-8s-8 3.589-8 8 3.589 8 8 8c1.846 0 3.543-.635 4.897-1.688l3.396 3.395c.195.195.451.293.707.293.256 0 .512-.098.707-.293.391-.391.391-1.023 0-1.414"
            />
        </Icon>
    )
}

export const AddTaskIcon = (props) => {
    return (
        <Icon {...props} width="24px" height="24px" viewBox="0 0 24 24">
            <path
                fill="currentColor"
                d="M15 11h-2V9c0-.55-.45-1-1-1s-1 .45-1 1v2H9c-.55 0-1 .45-1 1s.45 1 1 1h2v2c0 .55.45 1 1 1s1-.45 1-1v-2h2c.55 0 1-.45 1-1s-.45-1-1-1m4 7c0 .551-.448 1-1 1H6c-.552 0-1-.449-1-1V6c0-.551.448-1 1-1h12c.552 0 1 .449 1 1v12zM18 3H6C4.346 3 3 4.346 3 6v12c0 1.654 1.346 3 3 3h12c1.654 0 3-1.346 3-3V6c0-1.654-1.346-3-3-3z"
            />
        </Icon>
    )
}

export const ReportsIcon = (props) => {
    return (
        <Icon {...props} width="24px" height="24px" viewBox="0 0 24 24">
            <path
                fill="currentColor"
                id="htva6azf0a"
                d="M20.821 14.058c-.523-.186-1.093.087-1.276.609C18.417 17.857 15.385 20 12 20c-4.41 0-8-3.588-8-8 0-3.384 2.143-6.417 5.333-7.545.521-.183.794-.754.61-1.275-.184-.52-.755-.794-1.276-.61C4.68 3.98 2 7.77 2 12c0 5.514 4.486 10 10 10 4.231 0 8.02-2.678 9.43-6.667.184-.52-.088-1.091-.609-1.275M14 10V4.071c3.061.44 5.489 2.867 5.929 5.929H14zm-1-8c-.552 0-1 .447-1 1v8c0 .553.448 1 1 1h8c.552 0 1-.447 1-1 0-4.963-4.038-9-9-9z"
            />
        </Icon>
    )
}

export const CircleCheckIcon = (props) => {
    return (
        <Icon {...props} width="24px" height="24px" viewBox="0 0 24 24">
            <path
                id="0t4ihsztaa"
                d="M11.971 2c.795.012 1.605.092 2.381.278.536.13.867.67.737 1.207-.129.536-.672.866-1.206.738-.621-.15-1.28-.213-1.906-.223-2.137.006-4.144.844-5.65 2.36C4.82 7.873 3.994 9.885 4 12.022c.006 2.137.844 4.143 2.359 5.65C7.869 19.174 9.871 20 12 20h.023c2.137-.006 4.144-.844 5.65-2.359 1.507-1.516 2.333-3.527 2.327-5.664-.001-.552.445-1.002.997-1.003.554 0 1.002.446 1.003.997.008 2.671-1.025 5.186-2.909 7.08-1.883 1.894-4.391 2.941-7.062 2.95H12c-2.661 0-5.164-1.033-7.051-2.91C3.055 17.208 2.008 14.701 2 12.03c-.008-2.672 1.025-5.186 2.909-7.08C6.792 3.055 9.3 2.01 11.971 2zm6.277 4.341c.364-.413.995-.457 1.411-.093.415.363.457.995.093 1.411l-7 8c-.182.208-.442.331-.719.341H12c-.265 0-.519-.105-.707-.293l-3-3c-.391-.391-.391-1.023 0-1.414s1.023-.391 1.414 0l2.244 2.244z"
            />
        </Icon>
    )
}

export const AttachmentIcon = (props) => {
    return (
        <Icon {...props} width="24px" height="24px" viewBox="0 0 24 24">
            <path
                fill="currentColor"
                d="M10 18.333c-2.758 0-5-2.13-5-4.748V5.11c0-1.898 1.62-3.443 3.61-3.443 1.992 0 3.613 1.545 3.613 3.443l-.006 8.48c0 1.18-.997 2.138-2.222 2.138s-2.222-.959-2.222-2.138l.005-7.826c0-.46.374-.832.833-.832.46 0 .834.373.833.834L9.44 13.59c0 .259.248.47.555.47.307 0 .556-.211.556-.471l.005-8.48c0-.98-.873-1.777-1.945-1.777S6.667 4.13 6.667 5.11v8.475c0 1.7 1.495 3.082 3.333 3.082s3.333-1.383 3.333-3.082V5.11c0-.46.374-.833.834-.833.46 0 .833.372.833.833v8.475c0 2.617-2.242 4.748-5 4.748"
                transform="translate(-890 -318) translate(0 -3) translate(32 317) translate(858 4)"
            ></path>
        </Icon>
    )
}

export const AttachmentSlantIcon = (props) => {
    return (
        <Icon {...props} width="24px" height="24px" viewBox="0 0 24 24">
            <path
                fill="currentColor"
                d="M9.293 21c-1.655 0-3.23-.666-4.431-1.875-2.389-2.403-2.486-6.213-.22-8.494l7.386-7.43C12.798 2.427 13.835 2 14.95 2c1.194 0 2.328.479 3.192 1.349 1.721 1.731 1.788 4.482.146 6.132l-7.395 7.429c-.479.483-1.123.748-1.816.748-.73 0-1.423-.292-1.95-.821-1.053-1.061-1.085-2.752-.072-3.772L13.88 6.21c.39-.392 1.021-.394 1.413-.004.392.39.394 1.023.004 1.414l-6.825 6.856c-.24.242-.207.669.075.951.146.147.34.231.53.231.11 0 .268-.027.399-.158l7.394-7.429c.867-.873.802-2.358-.146-3.312-.906-.912-2.446-.984-3.277-.148l-7.386 7.43c-1.495 1.503-1.396 4.049.22 5.674C7.104 18.544 8.174 19 9.293 19c1 0 1.928-.378 2.61-1.064l7.387-7.43c.39-.391 1.023-.394 1.415-.004.39.39.393 1.022.003 1.414l-7.386 7.43C12.262 20.412 10.83 21 9.293 21"
                transform="translate(-880 -178) translate(32 174) translate(848 4)"
            ></path>
        </Icon>
    )
}

export const CarrotIcon = (props) => {
    return (
        <Icon {...props} width="24px" height="24px" viewBox="0 0 24 24">
            <path
                fill="currentColor"
                d="M12 16c-.228 0-.455-.077-.64-.232l-6-5c-.424-.353-.482-.984-.128-1.408.353-.424.983-.481 1.408-.128l5.371 4.476 5.362-4.315c.43-.346 1.06-.278 1.406.152.346.43.278 1.059-.152 1.406l-6 4.828c-.183.147-.405.221-.627.221"
            ></path>
        </Icon>
    )
}

export const LabelIcon = (props) => {
    return (
        <Icon {...props} width="24px" height="25" viewBox="0 0 24 25">
            <path
                fill="currentColor"
                d="M10.56 8.44c.586.585.586 1.536 0 2.12-.585.586-1.535.586-2.12 0-.586-.585-.586-1.536 0-2.12.585-.586 1.535-.586 2.12 0m-4.726 4.452l6.032 6.033 7.058-7.058-6.032-6.033-7.785-.726.727 7.784zm6.032 8.107c-.465 0-.929-.177-1.284-.53L4.165 14.05c-.165-.164-.267-.382-.289-.614l-.872-9.344c-.027-.296.078-.59.289-.8.211-.211.503-.318.8-.288l9.344.872c.232.02.449.123.614.288l6.417 6.417c.343.343.532.799.532 1.285 0 .486-.189.942-.532 1.285l-7.317 7.316c-.354.354-.819.53-1.285.53z"
            ></path>
        </Icon>
    )
}

export const SharingIcon = (props) => {
    return (
        <Icon {...props} width="24px" height="24px" viewBox="0 0 24 24">
            <path
                fill="currentColor"
                d="M10 13c-3.859 0-7 3.14-7 7 0 .552.447 1 1 1 .553 0 1-.448 1-1 0-2.757 2.243-5 5-5s5 2.243 5 5c0 .552.447 1 1 1 .553 0 1-.448 1-1 0-3.86-3.141-7-7-7M8 7c0 1.103.897 2 2 2s2-.897 2-2-.897-2-2-2-2 .897-2 2M6 7c0-2.206 1.794-4 4-4s4 1.794 4 4-1.794 4-4 4-4-1.794-4-4m16 0c0 .55-.45 1-1 1h-1v1c0 .55-.45 1-1 1s-1-.45-1-1V8h-1c-.55 0-1-.45-1-1s.45-1 1-1h1V5c0-.55.45-1 1-1s1 .45 1 1v1h1c.55 0 1 .45 1 1"
                transform="translate(-930 -318) translate(0 -3) translate(32 317) translate(898 4)"
            ></path>
        </Icon>
    )
}

export const CalendarIcon = (props) => {
    return (
        <Icon {...props} width="24px" height="24px" viewBox="0 0 24 24">
            <path
                fill="currentColor"
                d="M8 15c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm8 0c.55 0 1 .45 1 1s-.45 1-1 1h-4c-.55 0-1-.45-1-1s.45-1 1-1zm2 5H6c-.551 0-1-.449-1-1v-6h14v6c0 .551-.449 1-1 1M6 6h1v1c0 .55.45 1 1 1s1-.45 1-1V6h6v1c0 .55.45 1 1 1s1-.45 1-1V6h1c.551 0 1 .449 1 1v4H5V7c0-.551.449-1 1-1m12-2h-1V3c0-.55-.45-1-1-1s-1 .45-1 1v1H9V3c0-.55-.45-1-1-1s-1 .45-1 1v1H6C4.346 4 3 5.346 3 7v12c0 1.654 1.346 3 3 3h12c1.654 0 3-1.346 3-3V7c0-1.654-1.346-3-3-3"
                transform="translate(-970 -318) translate(0 -3) translate(32 317) translate(938 4)"
            ></path>
        </Icon>
    )
}

export const KebabIcon = (props) => {
    return (
        <Icon {...props} width="24px" height="25px" viewBox="0 0 24 25">
            <path
                fill="currentColor"
                d="M12 17c1.104 0 2 .896 2 2s-.896 2-2 2-2-.896-2-2 .896-2 2-2zm0-7c1.104 0 2 .896 2 2s-.896 2-2 2-2-.896-2-2 .896-2 2-2zm0-7c1.104 0 2 .896 2 2s-.896 2-2 2-2-.896-2-2 .896-2 2-2z"
                transform="translate(-1010 -318) translate(0 -3) translate(32 317) translate(978 4.333)"
            ></path>
        </Icon>
    )
}

export const MeatballIcon = (props) => {
    return (
        <Icon {...props} width="24px" height="24px" viewBox="0 0 16 16">
            <path
                fill="currentColor"
                d="M8 6.667c.736 0 1.333.597 1.333 1.333S8.736 9.333 8 9.333 6.667 8.736 6.667 8 7.264 6.667 8 6.667zm4.667 0C13.403 6.667 14 7.264 14 8s-.597 1.333-1.333 1.333S11.333 8.736 11.333 8s.598-1.333 1.334-1.333zm-9.334 0c.736 0 1.334.597 1.334 1.333s-.598 1.333-1.334 1.333C2.597 9.333 2 8.736 2 8s.597-1.333 1.333-1.333z"
                transform="translate(-829.000000, -129.000000) translate(821.000000, 125.000000) translate(8.000000, 4.000000)"
            />
        </Icon>
    )
}

export const LoopIcon = (props) => {
    return (
        <Icon {...props} width="24px" height="24px" viewBox="0 0 24 24">
            <path
                fill="currentColor"
                d="M9.167.417c.46 0 .833.372.833.833 0 .46-.372.833-.833.833h-2.06c-.701 0-1.274.595-1.274 1.325v9.997l1.078-1.077c.326-.325.852-.325 1.178 0 .163.162.244.375.244.589 0 .213-.081.426-.244.589l-2.5 2.5c-.326.326-.852.326-1.178 0l-2.5-2.5c-.326-.325-.326-.853 0-1.178.326-.325.852-.325 1.178 0l1.078 1.077V3.408c0-1.649 1.319-2.991 2.94-2.991zM14.41.66c.326-.326.852-.326 1.178 0l2.5 2.5c.163.163.244.376.244.59 0 .213-.081.426-.244.588-.326.326-.852.326-1.178 0l-1.078-1.077v9.997c0 1.65-1.319 2.991-2.94 2.991h-2.06c-.46 0-.833-.372-.833-.833 0-.461.373-.833.833-.833h2.06c.702 0 1.274-.595 1.274-1.325V3.262l-1.078 1.077c-.326.326-.852.326-1.178 0-.326-.325-.326-.852 0-1.178z"
                transform="translate(-847 -85) translate(799) translate(24 72) translate(24 14.5) translate(0 1.667) rotate(-90 10 8.333)"
            ></path>
        </Icon>
    )
}

export const PencilIcon = (props) => {
    return (
        <Icon {...props} width="24px" height="24px" viewBox="0 0 16 16">
            <path
                fill="currentColor"
                d="M10.68 7.12L8.881 5.321l1.3-1.299 1.795 1.796-1.298 1.3zm-4.627 4.63l-1.985.18.176-1.97L7.99 6.215l1.797 1.797-3.733 3.738zm6.883-6.858L11.11 3.065c-.494-.493-1.343-.516-1.81-.046L3.301 9.017c-.218.217-.352.505-.38.81l-.253 2.78c-.017.196.053.39.193.53.126.127.296.196.471.196.02 0 .04 0 .06-.003l2.78-.252c.306-.028.593-.162.81-.379l5.998-5.998c.485-.487.465-1.299-.045-1.81z"
                transform="translate(-966.000000, -275.000000) translate(640.000000, 157.000000) translate(218.247423, 110.000000) translate(108.000000, 8.000000)"
            />
        </Icon>
    )
}

export const TemplateIcon = (props) => {
    return (
        <Icon {...props} width="24px" height="24px" viewBox="0 0 24 24">
            <path
                fill="currentColor"
                d="M11.667 10.833h-.834V10c0-.46-.372-.833-.833-.833-.46 0-.833.373-.833.833v.833h-.834c-.46 0-.833.374-.833.834 0 .46.372.833.833.833h.834v.833c0 .46.372.834.833.834.46 0 .833-.374.833-.834V12.5h.834c.46 0 .833-.373.833-.833 0-.46-.373-.834-.833-.834m2.87 5.834H5.463c-.255 0-.463-.187-.463-.417V3.75c0-.23.208-.417.463-.417H10v2.625c0 1.31 1.014 2.375 2.262 2.375H15v7.917c0 .23-.207.417-.463.417zm-2.87-12.519l2.285 2.519h-1.69c-.328 0-.595-.318-.595-.709v-1.81zM16.45 6.94l-4.536-5c-.159-.174-.382-.273-.618-.273H5.463c-1.174 0-2.13.935-2.13 2.083v12.5c0 1.148.956 2.083 2.13 2.083h9.074c1.174 0 2.13-.935 2.13-2.083V7.5c0-.207-.078-.407-.217-.56z"
                transform="translate(-847 -230) translate(799 192.016) translate(24 24) translate(24 12) translate(0 2)"
            ></path>
        </Icon>
    )
}

export const TemplateNavIcon = (props) => {
    return (
        <Icon {...props} width="24px" height="24px" viewBox="0 0 20 20">
            <path
                fill="currentColor"
                d="M11.667 10.833h-.834V10c0-.46-.372-.833-.833-.833-.46 0-.833.373-.833.833v.833h-.834c-.46 0-.833.374-.833.834 0 .46.372.833.833.833h.834v.833c0 .46.372.834.833.834.46 0 .833-.374.833-.834V12.5h.834c.46 0 .833-.373.833-.833 0-.46-.373-.834-.833-.834m2.87 5.834H5.463c-.255 0-.463-.187-.463-.417V3.75c0-.23.208-.417.463-.417H10v2.625c0 1.31 1.014 2.375 2.262 2.375H15v7.917c0 .23-.207.417-.463.417zm-2.87-12.519l2.285 2.519h-1.69c-.328 0-.595-.318-.595-.709v-1.81zM16.45 6.94l-4.536-5c-.159-.174-.382-.273-.618-.273H5.463c-1.174 0-2.13.935-2.13 2.083v12.5c0 1.148.956 2.083 2.13 2.083h9.074c1.174 0 2.13-.935 2.13-2.083V7.5c0-.207-.078-.407-.217-.56z"
                transform="translate(-847 -230) translate(799 192.016) translate(24 24) translate(24 12) translate(0 2)"
            ></path>
        </Icon>
    )
}

export const TrashIcon = (props) => {
    return (
        <Icon {...props} width="24px" height="24px" viewBox="-2 -2 24 24">
            <path
                fill="currentColor"
                d="M7.5 9.167c.458 0 .833.375.833.833v3.333c0 .459-.375.834-.833.834-.458 0-.833-.375-.833-.834V10c0-.458.375-.833.833-.833zm5 0c.458 0 .833.375.833.833v3.333c0 .459-.375.834-.833.834-.458 0-.833-.375-.833-.834V10c0-.458.375-.833.833-.833zm2.5 6.666c0 .46-.373.834-.833.834H5.833c-.46 0-.833-.375-.833-.834V6.667h10v9.166zM8.333 3.607c0-.13.179-.274.417-.274h2.5c.238 0 .417.144.417.274V5H8.333V3.607zM17.5 5h-4.167V3.607c0-1.07-.934-1.94-2.083-1.94h-2.5c-1.15 0-2.083.87-2.083 1.94V5H2.5c-.458 0-.833.375-.833.833 0 .459.375.834.833.834h.833v9.166c0 1.379 1.122 2.5 2.5 2.5h8.334c1.378 0 2.5-1.121 2.5-2.5V6.667h.833c.458 0 .833-.375.833-.834 0-.458-.375-.833-.833-.833z"
                transform="translate(-847 -134) translate(799) translate(24 120) translate(24 12) translate(0 2)"
            />
        </Icon>
    )
}

export const CopyIcon = (props) => {
    return (
        <Icon {...props} width="24px" height="24px" viewBox="0 0 24 24">
            <path
                fill="currentColor"
                d="M7.5 10v.833H4.723c-.307 0-.556-.249-.556-.556V4.723c0-.307.249-.556.556-.556h5.554c.307 0 .556.249.556.556V7.5H10c-1.378 0-2.5 1.122-2.5 2.5M15 7.5h-2.5V4.723c0-1.226-.997-2.223-2.223-2.223H4.723C3.497 2.5 2.5 3.497 2.5 4.723v5.554c0 1.226.997 2.223 2.223 2.223H7.5V15c0 1.378 1.122 2.5 2.5 2.5h5c1.378 0 2.5-1.122 2.5-2.5v-5c0-1.378-1.122-2.5-2.5-2.5"
                transform="translate(-375 -155) translate(40) translate(325 145) translate(10 10)"
            />
        </Icon>
    )
}

export const PlusIcon = (props) => {
    return (
        <Icon {...props} width="24px" height="24px" viewBox="0 0 20 20">
            <path
                fill="currentColor"
                d="M15.833 9.167h-5v-5c0-.461-.373-.834-.833-.834-.46 0-.833.373-.833.834v5h-5c-.46 0-.834.372-.834.833 0 .46.374.833.834.833h5v5c0 .461.373.834.833.834.46 0 .833-.373.833-.834v-5h5c.46 0 .834-.372.834-.833 0-.46-.374-.833-.834-.833"
                transform="translate(-1073 -27) translate(1063 17) translate(10 10)"
            />
        </Icon>
    )
}

export const ProfileIcon = (props) => {
    return (
        <Icon {...props} width="24px" height="24px" viewBox="0 2 22 22">
            <path
                fill="currentColor"
                d="M19 20c0 .552-.447 1-1 1-.553 0-1-.448-1-1 0-2.757-2.243-5-5-5s-5 2.243-5 5c0 .552-.447 1-1 1-.553 0-1-.448-1-1 0-3.86 3.141-7 7-7s7 3.14 7 7M12 5c1.103 0 2 .897 2 2s-.897 2-2 2-2-.897-2-2 .897-2 2-2m0 6c2.206 0 4-1.794 4-4s-1.794-4-4-4-4 1.794-4 4 1.794 4 4 4"
            />{' '}
        </Icon>
    )
}

export const TasksIcon = (props) => {
    return (
        <Icon {...props} width="24px" height="24px" viewBox="0 0 24 24">
            <path
                fill="currentColor"
                id="ducqtzlmga"
                d="M15.167 3c.552 0 1 .447 1 1 0 .553-.448 1-1 1H5.6c-.331 0-.6.27-.6.6v12.8c0 .33.269.6.6.6h12.8c.331 0 .6-.27.6-.6v-5.566c0-.553.448-1 1-1s1 .447 1 1V18.4c0 1.434-1.167 2.6-2.6 2.6H5.6C4.167 21 3 19.834 3 18.4V5.6C3 4.168 4.167 3 5.6 3zm3.115 3.304c.383-.396 1.016-.408 1.414-.022.397.383.407 1.016.023 1.413l-6.777 7c-.188.195-.447.306-.718.306-.276-.002-.536-.114-.724-.31l-2.223-2.33c-.382-.4-.366-1.034.033-1.415.4-.381 1.032-.365 1.414.034l1.505 1.577z"
            />
        </Icon>
    )
}

export const SignOutIcon = (props) => {
    return (
        <Icon {...props} width="24px" height="24px" viewBox="0 0 24 24">
            <path
                fill="currentColor"
                d="M7 4c.55 0 1 .45 1 1s-.45 1-1 1H6v12h1c.55 0 1 .45 1 1s-.45 1-1 1H5c-.55 0-1-.45-1-1V5c0-.55.45-1 1-1zm9.61 3.182c.452-.32 1.076-.21 1.394.243l2.814 4c.25.354.242.829-.018 1.175l-3 4c-.196.262-.497.4-.801.4-.208 0-.42-.065-.6-.2-.441-.331-.53-.958-.2-1.4l1.802-2.4H10c-.552 0-1-.447-1-1 0-.553.448-1 1-1h8c.03 0 .056.015.086.018l-1.718-2.443c-.318-.451-.21-1.075.243-1.393z"
            />{' '}
        </Icon>
    )
}

export const ActivityIcon = (props) => {
    return (
        <Icon {...props} width="24px" height="24px" viewBox="0 0 24 24">
            <path
                fill="currentColor"
                id="q9l8j61dua"
                d="M5.515 16l1.18-1.182c.378-.378.586-.88.586-1.414V8.727c0-1.357.59-2.654 1.62-3.556 1.038-.91 2.36-1.31 3.737-1.129 2.327.309 4.082 2.413 4.082 4.895v4.467c0 .534.208 1.036.585 1.413L18.485 16H5.516zM14 18.341C14 19.24 13.084 20 12 20s-2-.76-2-1.659V18h4v.341zm6.52-3.133l-1.8-1.804V8.937c0-3.481-2.502-6.438-5.82-6.877-1.922-.256-3.862.331-5.317 1.607C6.119 4.949 5.28 6.793 5.28 8.727l-.001 4.677-1.801 1.804c-.47.47-.608 1.169-.354 1.782.255.614.848 1.01 1.512 1.01H8v.341C8 20.359 9.794 22 12 22s4-1.641 4-3.659V18h3.363c.664 0 1.256-.396 1.51-1.009.255-.614.117-1.314-.352-1.783z"
            />
        </Icon>
    )
}

export const DownloadIcon = (props) => {
    return (
        <Icon {...props} width="24px" height="24px" viewBox="0 0 20 20">
            <path
                fill="currentColor"
                d="M11.925 10.345l-1.092 1.042v-4.72c0-.46-.373-.834-.833-.834-.46 0-.834.374-.834.834v4.655L8.09 10.244c-.325-.326-.853-.326-1.179 0-.325.326-.325.853 0 1.179l2.5 2.499c.077.077.17.138.271.18.102.043.21.065.319.065.107 0 .214-.023.314-.064.05-.02.09-.056.135-.086.042-.028.089-.044.127-.08l2.5-2.386c.332-.318.345-.845.027-1.178-.318-.334-.845-.345-1.178-.028M10 16.667c-3.676 0-6.667-2.991-6.667-6.667 0-3.676 2.991-6.667 6.667-6.667 3.676 0 6.667 2.991 6.667 6.667 0 3.676-2.991 6.667-6.667 6.667m0-15c-4.595 0-8.333 3.738-8.333 8.333 0 4.595 3.738 8.333 8.333 8.333 4.595 0 8.333-3.738 8.333-8.333 0-4.595-3.738-8.333-8.333-8.333"
                transform="translate(-902 -114) translate(890 104) translate(12 10)"
            />
        </Icon>
    )
}

export const CloseIcon = (props) => {
    return (
        <Icon width="24px" height="24px" viewBox="0 0 24 24" {...props}>
            <path
                fill="currentColor"
                id="d0l4x0n45a"
                d="M13.414 12l4.293-4.293c.391-.391.391-1.023 0-1.414-.39-.391-1.023-.391-1.414 0L12 10.586 7.707 6.293c-.39-.391-1.023-.391-1.414 0-.39.391-.39 1.023 0 1.414L10.586 12l-4.293 4.293c-.39.391-.39 1.023 0 1.414.195.195.451.293.707.293.256 0 .512-.098.707-.293L12 13.414l4.293 4.293c.195.195.451.293.707.293.256 0 .512-.098.707-.293.391-.391.391-1.023 0-1.414L13.414 12z"
            />
        </Icon>
    )
}

export const ClockIcon = (props) => {
    return (
        <Icon {...props} width="24px" height="24px" viewBox="0 0 24 24">
            <path
                fill="currentColor"
                id="us6t2tx4ra"
                d="M16 11h-3V8c0-.553-.448-1-1-1s-1 .447-1 1v4c0 .553.448 1 1 1h4c.553 0 1-.447 1-1 0-.553-.447-1-1-1m-4 9c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8m0-18C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2"
            />
        </Icon>
    )
}
export const SubmitIcon = (props) => {
    return (
        <Icon {...props} width="21px" height="20px" viewBox="0 0 21 20">
            <path
                d="M5.132 5.833a.84.84 0 0 1-.843.834.84.84 0 0 1-.843-.834V4.167a.84.84 0 0 1 .843-.834h11.8a.84.84 0 0 1 .844.834v1.666a.84.84 0 0 1-.843.834.84.84 0 0 1-.843-.834V5H5.13v.834zM7.324 11.5a.845.845 0 0 1-1.18-.167.826.826 0 0 1 .168-1.166l3.372-2.5a.85.85 0 0 1 .99-.015l3.372 2.345a.827.827 0 0 1 .205 1.16.85.85 0 0 1-1.175.203L11.03 9.934a.836.836 0 0 1 .002.066v6.667c0 .46-.376.833-.843.833a.838.838 0 0 1-.843-.833V10l-2.022 1.5z"
                fill="currentColor"
            />
        </Icon>
    )
}
export const LeftArrow = (props) => {
    return (
        <Icon {...props} width="16px" height="16px" viewBox="0 0 16 16">
            <path
                d="M9.22 12.667a.665.665 0 0 1-.52-.249l-3.22-4a.667.667 0 0 1 .008-.845l3.333-4a.668.668 0 0 1 1.024.854l-2.983 3.58 2.876 3.575a.666.666 0 0 1-.519 1.085"
                fill="currentColor"
            />
        </Icon>
    )
}
