import React from 'react'
import { VStack, Menu, MenuList, Divider } from '@chakra-ui/react'
import MobileNavButton from './MobileNavButton'
import MobileNavMenuItem from './MobileNavMenuItem'

const BUTTONS = [
    {
        left: '📥',
        text: 'Submit A Task',
    },
    {
        left: '✅',
        text: 'Submitted',
    },
]
export default function MobileNav({ section, setSection, numSubmittedTasks }) {
    return (
        <VStack justifyItems="center" w="100%">
            <Menu>
                <MobileNavButton
                    left={BUTTONS[section].left}
                    text={BUTTONS[section].text}
                />
                <MenuList>
                    {BUTTONS.map((b, i) => (
                        <MobileNavMenuItem
                            left={b.left}
                            text={b.text}
                            right={i === 1 && numSubmittedTasks}
                            selected={section === i}
                            handleClick={() => setSection(i)}
                            key={i}
                        />
                    ))}
                </MenuList>
            </Menu>
        </VStack>
    )
}
