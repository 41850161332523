import React, { useState, useEffect } from 'react'
import { Input, Kbd, Flex, Button, Box, Progress } from '@chakra-ui/react'
import { AddTaskIcon } from './ChakraDesign/Icons'
import InboxCard from './InboxCard'

export default function CreateTaskInput({
    executive,
    createTask,
    placeholder,
    submissionStep,
    setSubmissionStep,
    description,
    setDescription,
    files,
    setFiles,
    due,
    setDue,
}) {
    const handleChange = (e) => {
        e.preventDefault()
        setDescription(e.target.value)
        setSubmissionStep(1)
    }

    const handleCreate = () => {
        setSubmissionStep(2)
    }

    return (
        <Box width="100%">
            {/* task bar to denote progress */}
            {submissionStep > 0 && (
                <Progress
                    value={(submissionStep / 3) * 100}
                    colorScheme="blue"
                    borderRadius="16px"
                    height="8px"
                    width="100%"
                    marginTop="8px"
                    marginBottom="8px"
                />
            )}
            {submissionStep < 2 && (
                <Flex alignItems="center" width="100%">
                    <AddTaskIcon
                        color="#8f9bb3"
                        style={{
                            marginTop: '2px',
                            marginLeft: '-1px',
                            marginRight: '9px',
                        }}
                    />
                    <Input
                        id="create-task-input"
                        placeholder={placeholder}
                        // autoComplete="new-password"
                        variant="unstyled"
                        name="description"
                        size="lg"
                        value={description}
                        onChange={handleChange}
                        onKeyDown={(e) => {
                            if (Number(e.keyCode) === 13) {
                                e.preventDefault()
                                e.stopPropagation()
                                handleCreate()
                            }
                        }}
                        autoComplete="off"
                    />
                    {description.length > 0 && (
                        <Button
                            colorScheme="blue"
                            variant="solid"
                            size="md"
                            marginLeft="16px"
                            onClick={handleCreate}
                            display="flex"
                            minW="120px"
                        >
                            <span
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                create{' '}
                                <Kbd
                                    color="black"
                                    marginLeft="4px"
                                    borderColor="grey.800"
                                >
                                    enter
                                </Kbd>
                            </span>
                        </Button>
                    )}
                </Flex>
            )}
            {/* the task card of the task they are submitting */}
            {submissionStep === 2 && (
                <InboxCard
                    description={description}
                    setDescription={setDescription}
                    due={due}
                    setDue={setDue}
                    files={files}
                    setFiles={setFiles}
                    open
                    canEditDescription
                />
            )}
        </Box>
    )
}
