import React from 'react'
import { Center, Text, Flex } from '@chakra-ui/react'
import { Logo } from './Navbar/Logo'

export default function HomePage() {
    return (
        <Center mt="48px">
            <Flex maxWidth="560px" flexDir="column" padding="24px">
                <Logo></Logo>
                <Text fontSize="lg">
                    Welcome to the <b>Office Otter Inbox</b> where you can
                    submit a task to an Office Otter user via a{' '}
                    <b>custom link</b>. <br />
                    <br />
                    It will look something like <br />
                    <br />
                    <Center>
                        inbox.officeotter.com/
                        <b>custom-name-here</b>
                    </Center>
                    <br /> Ask whomever sent you to this page for their custom
                    link to get started.
                </Text>
            </Flex>
        </Center>
    )
}
