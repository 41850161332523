import React, { useState } from 'react'
import { Box, FormControl, FormLabel, Text, Flex } from '@chakra-ui/react'
import { covertTimeTo12forMenu, convertTime12to24 } from './Util/timeUtils'
import { setMinutes, setHours } from 'date-fns'
import TimePicker from './TimePicker'
import DatePicker from './DatePicker'
import OOModal from './OOModal'

export default function DueDateModal({
    due,
    submit,
    executive,
    isOpen,
    onClose,
}) {
    const [date, setDate] = useState(due ? new Date(due) : new Date())
    const [invalidTime, setInvalidTime] = useState(false)
    const [time, setTime] = useState(
        due ? covertTimeTo12forMenu(new Date(due)) : '10:00AM'
    )

    const handleSubmit = () => {
        const [hours, minutes] = convertTime12to24(time).split(':')
        let fullDate = setHours(new Date(date), hours)
        fullDate = setMinutes(fullDate, minutes)

        submit(fullDate)
    }

    const handleRemove = () => {
        submit({ due: null })
        onClose()
    }

    return (
        <OOModal
            title="Set a due date"
            onSubmit={handleSubmit}
            disableSubmit={invalidTime}
            secondaryButton={
                due && {
                    onClick: handleRemove,
                    text: 'remove',
                }
            }
            isOpen={isOpen}
            onClose={onClose}
        >
            <Box>
                <FormControl id="date">
                    <FormLabel fontSize="small">Date</FormLabel>
                    <DatePicker date={new Date(date)} setDate={setDate} />
                </FormControl>
                <TimePicker
                    time={time}
                    setTime={setTime}
                    invalidTime={invalidTime}
                    setInvalidTime={setInvalidTime}
                />
            </Box>
        </OOModal>
    )
}
