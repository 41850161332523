import React from 'react'
import {
    AutoComplete,
    AutoCompleteInput,
    AutoCompleteItem,
    AutoCompleteList,
} from '@choc-ui/chakra-autocomplete'
import { FormControl, FormLabel } from '@chakra-ui/react'
import { times, validateTime } from './Util/timeUtils'
import theme from './ChakraDesign/theme'

export default function TimePicker({
    time,
    setTime,
    invalidTime,
    setInvalidTime,
}) {
    const updateTime = (updatedTime) => {
        setTime(updatedTime)

        const validatedTime = validateTime(updatedTime)
        if (validatedTime) {
            setTime(validatedTime)
            setInvalidTime(false)
        } else {
            setInvalidTime(true)
        }
    }

    return (
        <FormControl mt="16px">
            <FormLabel fontSize="small">
                Time{' '}
                {invalidTime && (
                    <span
                        style={{ marginLeft: 4, color: theme.colors.red[500] }}
                    >
                        *Invalid time
                    </span>
                )}
            </FormLabel>
            <AutoComplete
                rollNavigation
                openOnFocus
                onSelectOption={(obj) => updateTime(obj.item.value)}
            >
                <AutoCompleteInput
                    placeholder="10:00AM"
                    value={time}
                    onChange={(e) => updateTime(e.target.value)}
                />
                <AutoCompleteList>
                    {times.map((option, index) => (
                        <AutoCompleteItem
                            key={`option-${index}`}
                            value={option}
                            label={option}
                        />
                    ))}
                </AutoCompleteList>
            </AutoComplete>
        </FormControl>
    )
}
