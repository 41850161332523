import React, { useState, useContext } from 'react'
import { Flex, Button, Box, Text, Tooltip } from '@chakra-ui/react'
import { format } from 'date-fns'
import { ClockIcon, AttachmentSlantIcon } from './ChakraDesign/Icons'
import { apiCall } from './Util/api'
import UploadButton from './UploadButton'
import DueDateModal from './DueDateModal'
import { ExecutiveContext } from './Contexts/ExecutiveContext'

export default function InboxActionsBar({
    due,
    addDueDate,
    files,
    setFiles,
    submittedSection,
    isOverDue,
}) {
    const [isDueDateModalOpen, setIsDueDateModalOpen] = useState(false)
    const { executive, setExecutive } = useContext(ExecutiveContext)
    const addFile = async (formData) => {
        const response = await apiCall(
            `POST`,
            `/users/${executive._id}/files?inbox=true`,
            formData
        )
        let newFiles = [...files]
        newFiles.push(response._id)
        setFiles(newFiles)
        return response
    }

    const handleUploadSuccess = (data) => {
        console.log(data)
    }

    const handleAddDueDate = (due) => {
        addDueDate(due)
    }

    return (
        <>
            <Flex alignItems="center" justifyContent="flex-end">
                {due === null ? (
                    <>
                        {!submittedSection && (
                            <Tooltip label="Set a due date">
                                <Flex>
                                    <Button
                                        onClick={() =>
                                            setIsDueDateModalOpen(true)
                                        }
                                        leftIcon={<ClockIcon />}
                                        color="grey.500"
                                        mr="16px"
                                    >
                                        Due Date
                                    </Button>
                                </Flex>
                            </Tooltip>
                        )}
                    </>
                ) : (
                    <Text
                        fontSize="sm"
                        alignSelf="center"
                        display={{
                            base: 'none',
                            md: 'flex',
                        }}
                        marginLeft="4px"
                        marginRight="16px"
                        color={isOverDue(due) ? 'red.500' : 'colors.black'}
                    >
                        {`Due: ${format(new Date(due), 'MMM d, h:mma')}`}
                    </Text>
                )}
                {!submittedSection && (
                    <UploadButton
                        addFile={addFile}
                        onUploadSuccess={handleUploadSuccess}
                    >
                        <Tooltip label="Add attachment">
                            <Button
                                leftIcon={<AttachmentSlantIcon />}
                                color="grey.500"
                            >
                                Attachment
                            </Button>
                        </Tooltip>
                    </UploadButton>
                )}
            </Flex>
            {isDueDateModalOpen && (
                <DueDateModal
                    isOpen={isDueDateModalOpen}
                    submit={handleAddDueDate}
                    onClose={() => setIsDueDateModalOpen(false)}
                />
            )}
        </>
    )
}
